<template>
  <div>
    <div class="ser-form">
      <Form :model="formData" :label-width="100">
        <FormItem label="套餐包类型">
          <div class="radio-box">
            <div class="radio-box-options">
              <span class="radio-box-item" v-for="(item,index) in data" :class="{'radio_box_item_select': type == index }" :key="index" @click="type=index">{{ item.name }}</span>
            </div>
          </div>
        </FormItem>
        <FormItem label="资源包规格">
          <div class="radio-box">
            <div class="radio-box-options">
              <span class="radio-box-item" v-for="(item,index) in data[type]['children']" :key="index" :class="{'radio_box_item_select': sku == index }" @click='sku=index'>{{ item.specs+'条' }}</span>
            </div>
          </div>
        </FormItem>
        <FormItem label="支付方式">
          <div class="pay_type">
            <div class="pay_item" :class="{'active':payment==2}" @click="payment=2">
              <i class="iconfont icon-weixinzhifu"></i>
              <span class="text">微信支付</span>
              <i v-if="payment==2" class="iconfont icon-xuanze"></i>
            </div>
          </div>
        </FormItem>
        <FormItem :label-width="0">
          <div class="price-active">
            <div class="price-fee">
              <div class="price-fee-item">
                <span>总配置费用</span>
                <span class="price-fee-amount"><strong>{{ data[type]['children'][sku]['money']+'元' }}</strong></span>
              </div>
            </div>
            <span class="price-btn" @click="sms_buy">确认购买</span>
          </div>
        </FormItem>
      </Form>
    </div>
	<Spin size="large" fix v-if="spin_show">
		<Icon type="ios-loading" :size='18' class="demo-spin-icon-load"></Icon>
		<div>订单生成中</div>
	</Spin>
	<Modal :mask-closable="false" v-model="pay_show" @on-cancel="cancel_pay()" class-name="modal" :transfer="false"
		:footer-hide="true" width="250">
		<div style="text-align: center;width: 100%;font-weight: bold;">
			微信支付
		</div>
		<vue-qr :text="code_url" :size="220"></vue-qr>
	</Modal>
  </div>
</template>

<script>
	import vueQr from 'vue-qr'
    export default {
        name: "Package",
		components: {
			vueQr
		},
        data(){
            return{
				timeout: null,
				spin_show: false,
				pay_show: false,
				code_url: '',
                formData:{tc:1},
                payment:2,
				sku:0,
				type:0,
				data: [{
					'children': [{
						money: ''
					}]
				}]
            }
        },
		created() {
			this.init()
		},
		//离开当前页面触发，作用于获取微信订单状态
		destroyed() {
			clearInterval(this.timeout)
		},
        methods:{
            init(){
                let _this = this
				
				this.requestApi('/adm/get_sms_buy',{}).then(
					(res) => {
						_this.data = res.data
					}
				)
            },
			sms_buy() {
				let _this = this
				
				let data = {id: this.data[this.type].id,payment: this.payment,specs:  this.data[this.type]['children'][this.sku],name: this.data[this.type]['name'],type: this.data[this.type].type}
				_this.spin_show = true
				this.requestApi('/adm/sms_buy',{data}).then(
					(res) => {
						if(res.code == 200) {
							_this.spin_show = false
							_this.pay_show = true
							_this.code_url = res.data.code_url
							this.timeout = setInterval(function() {
								_this.requestApi('/adm/get_sms_status', {
									order_number: res.data.order_number
								}).then(
									(res) => {
										if (res.data == 1) {
											clearInterval(_this.timeout)
											_this.$Message.success('支付成功')
											_this.pay_show = false
											setTimeout(function() {
												_this.$router.push('/sms/actionlist')
											}, 100)
										}
									}
								)
							}, 500)
						}
					}
				)
			},
			//点击取消
			cancel_pay() {
				clearInterval(this.timeout)
			}
        }
    }
</script>

<style scoped>
  .ser-form{
    padding: 16px;
    background-color: #FFFFFF;
  }
  .price-active{
    margin-top: 25px;
  }
  .radio-box-item{
    min-width: 152px;
    text-decoration: none;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid #e5e5e5;
    box-shadow: none;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    font-size: 12px;
    border-radius: 0;
    color: #262626;
    cursor: pointer;
  }
  .radio_box_item_select{
    border: 1px solid #00c1d0;
    color: #373d41;
    border-color: #b2d4ef;
    background: rgba(0,112,204,.15);
  }
  .radio-box-tips{
    font-size: 12px;
    color: #787e80;
    line-height: 1.5;
    margin-top: 10px;
  }
  .price-fee{
    font-size: 12px;
    display: inline-block;
    padding: 0 35px;
    vertical-align: middle;
    text-align: right;
  }
  .price-fee-item{
    line-height: 1.7;
  }
  .price-fee-amount{
    font-family: PingFangSC-Regular;
    margin-left: 5px;
    line-height: 17px;
    font-size: 24px;
    color: #ff8a00;
    letter-spacing: 0;
    text-align: right;
  }
  .price-fee-title{
    background: rgba(201,240,201,.5);
    color: #35b34a;
  }
  .price-fee-label{
    margin-left: 5px;
    color: #35b34a;
  }
  .price-fee-num{
    font-weight: 400;
    margin-left: 5px;
    color: #35b34a;
  }
  strong{
    font-weight: 500;
  }
  .price-btn{
    background: #ff8a00;
    min-width: 140px;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    margin-left: 12px;
    cursor: pointer;
  }
  .pay_type{
    display: flex;
  }
  .pay_item{
    margin-left: 10px;
    display: flex;
    justify-content: center;
    width: 150px;
    height: 50px;
    line-height: 50px;
    border: 2px solid #e4e4e4;
    cursor:pointer;
    text-align: center;
    font-size: 18px;
    position: relative;
  }
  .active{
    border: 2px solid #ff4544;
  }
  .icon-xuanze{
    position: absolute;
    top: -18px;
    right: -2px;
    color: #ff4544;
  }
  .pay_item .icon-zhifubao{
    color: #039feb;
    font-size: 26px;
    margin-right: 5px;
  }
  .pay_item .icon-weixinzhifu{
    font-size: 26px;
    color: #41b035;
    margin-right: 5px;
  }
  .pay_item .text{
    display: inline-block;
    line-height: 48px;
  }
</style>
